const language = [
  {
    /// ru
    header_title: "КНИГИ КОТОРЫЕ ТЫ ЕЩЕ НЕ ЧИТАЛ",
    button_text: "Найти больше книг",
    recommmend: "Рекомендации",
    hot: "Хиты продаж",
    collection: "Сборники",
    newest: "Новинки",
    company: "О компании",
    offerta: "Публичная оферта",
    help: "Служба поддержки",
    contact: "Контакты",
    publishmishment: "Издательствам",
    acompany: "Сотрудничество",
    author: "Автор",
    library: "Купленные книги",
    webmaster: "Вебмастерам",
    developers: "Разработано командой",
    search_title: "НАЙДИ СЕБЕ КНИГУ ПО ДУШЕ",
    search_placeHolder: "Название книги",
    search_button: "Найти",
    genre: "Жанры",
    datePublish: "Дата выпуска",
    search_type: "Тип",
    sorting: "Сортировка",
    all: "Все",
    user_title: "Выиграй свой автомобиль",
    mainReward: "Главная Награда",
    level: "Уровень",
    hello: "Привет",
    countOfFriends: "Количество друзей",
    countOfPurchase: "Количество покупок",
    sumPurchase: "Сумма покупок",
    reward: "Вознаграждение",
    referal_link: "Реферальная ссылка",
    user_library: "Библиотка",
    user_empty: "У вас нет книг!",
    copy: "Скопировать",
    copied: "Скопировано!",
    book_author: "Автор",
    rating: "Рейтинг",
    book_publish: "Дата выхода",
    volume: "Объем",
    pages: "стр",
    booksCount: "книг",
    tags: "Теги",
    download_format: "Форматы для скачивания",
    download: "Скачать",
    book_descript: "Описание книги",
    purchasing: "Купить",
    text: "Текст",
    audio: "Аудио",
    result: "Результаты",
    auth_title: "ВХОД В АККАУНТ",
    enter_phone: "Введите свой номер телефона",
    enter_code: "Введите код подтверждения",
    getCode: "Получить код",
    submit: "Подтвердить",
    notTaked: "Не получил код!",
    register_title: "СОЗДАНИЕ АККАУНТА",
    enter_name: "Введите ваше ИМЯ",
    save: "Сохранить",
    welcome: "Добро пожаловать",
    link_title:
      "Распространте ссылку своим друзьям и близким и получи шанс выйгратьSPARK!",
    more: "Подробнее",
    notNow: "Не сейчас",
    downloading: "Скачивание",
    error: "Что-то пошло не так!",
    goToCollection: "Перейти к сборнику",
    win: "Выиграй",
    carName: "автомобиль",
    agreement: "соглащаюсь с публичной офертой",
    self: "свой",
    credentials: "Данные вашей карты",
    enterCard: "Введите номер карты!",
    cashback: "Кэшбек",
    cardButton: "Вывести на карту",
    sale: "Подробнее об акции",
    present_register_1: "Регистрируйся",
    present_register_2: "Покупай книгу и",
    present_register_3: "приглащай",
    present_register_5: "приглащай",
    present_title_1: "и читай книги",
    present_title_2: "наслаждайся",
    present_title_3: "и зарабатывай",
    present_title_4: "это не только возврат!",
    present_title_5: "Наконец",
    present_text_1:
      "В нашей платформе более 5000 книг и журналов на разные темы и увлечения. У нас ты найдешь книгу души, можешь не выходя из дома путешествовать или окунутся в запутанный криминальный детектив.",
    present_text_2:
      "Во всех книгах вложена душа писателья. Все эмоции передаются через платформу",
    present_text_3:
      "Чем больше друзей вы позовите, тем больше покупок. Чем больше покупок, тем больше кэшбэков. Таким простым способом вы можете заработать и получить шанс выиграть суперприз.",
    present_text_4:
      "За покупку каждого друга можно получать кэшбэк. Даже если твой друг позовет своего друга, то кэшбэк получишь и ты. Приглашай друзей, зарабатывай и читай вместе.",
    present_text_5:
      "Никогда такого не было, что бы за прочтение книг дарили автомобили. А вот мы дарим, быстрее читай книги и побеждай. Не упусти шанс!",
  },
  {
    /// uz
    header_title: "O'qishga ulgurmagan kitoblaringiz",
    button_text: "Ko'proq kitob",
    recommmend: "Tavsiyalar",
    hot: "Ko‘p tanlangan",
    collection: "Toplamlar",
    newest: "Yangilari",
    company: "Kompaniya Haqida",
    offerta: "Takliflar",
    help: "Yordam",
    contact: "Kontaktlar",
    publishmishment: "Nashriyot",
    acompany: "Hamkorlik",
    author: "Muallif",
    library: "Sotib olingan kitoblar",
    webmaster: "Veb-ustalar",
    developers: "Jamoa tomonidan ishlandi",
    search_title: "KERAKLI KITOBNI TOPING",
    search_placeHolder: "Kitob Nomi",
    search_button: "Qidirish",
    genre: "Janrlar",
    datePublish: "Chop etilgan sana",
    search_type: "Turi",
    sorting: "Tartiblash",
    all: "Hammasi",
    user_title: "Avtomobil Yutib Oling",
    mainReward: "Asosiy Yutuq",
    level: "Bosqich",
    hello: "Salom",
    countOfFriends: "Do'stlar soni",
    countOfPurchase: "Xaridlar soni",
    sumPurchase: "Xaridlar summasi",
    reward: "Mukofot",
    referal_link: "Referal link",
    user_library: "Kutubxona",
    user_empty: "Sizda Kitoblar Yo'q!",
    copy: "Ko'chirish",
    copied: "Ko'chiriildi!",
    book_author: "Muallif",
    rating: "Reyting",
    volume: "Hajmi",
    tags: "Teglar",
    pages: "bet",
    booksCount: "ta kitob",
    download_format: "Yuklash formati",
    download: "Yuklash",
    book_descript: "Kitob tavsifi",
    purchasing: "Sotib olish",
    text: "Matn",
    audio: "Audio",
    result: "Natija",
    auth_title: "TIZIMGA KIRISH",
    enter_phone: "Telefon raqamingizni kiriting!",
    enter_code: "Tasdiqlash kodi kiriting!",
    getCode: "ko'dni olish",
    submit: "Tasdiqlash",
    notTaked: "Ko'dni olmadingizmi!",
    register_title: "AKKAUNT YARATISH",
    enter_name: "Ismingizni kiriting",
    save: "Saqlash",

    welcome: "Xush Kelibsiz!",
    link_title:
      "Do'st va Yaqinlaringiz bilan havolani ulashing va SPARK yutib olish imkoniyatini qo'lga kiriting!",
    more: "Batafsil",
    notNow: "Hozir emas",
    downloading: "Yuklanmoqda",
    error: "Xatolik yuz berdi!",
    goToCollection: "To'plamga o'tish",
    win: "yutib ol",
    carName: "avtomobilingni",
    self: "O'z",
    credentials: "Karta Malumotlari",
    enterCard: "Karta Raqamini kiriting!",
    cashback: "Keshbek",
    cardButton: "Kartaga chiqarish",
    sale: "Aksiya haqida",
    present_register_1: "Ro'yhatdan o'ting",
    present_register_2: "Kitob sotib oling va",
    present_register_3: "Taklif qiling",
    present_title_1: "va kitob mutolaa qiling",
    present_title_2: "rohatlaning",
    present_title_3: "Pul ishlang",
    present_title_4: "bu shunchaki pul qaytishi emas",
    agreement: "Ommaviy oferta shartlarini qabul qildim",
    present_title_5: "Vanihoyat",
    present_text_1:
      "Bizning platformamizda 5000 dan ortiq turli mavzu va qiziqishlardagi kitob va jurnallar mavjud. Bu yerda siz ko'nglizdagi kitoblarni topishingiz mumkin. Uyni tark etmasdan, jinoiy detektivlar olamiga sayohat qiling yoki sho'ng'ing!",
    present_text_2:
      "Barcha kitoblarda yozuvchi ruhi bor. Barcha his-tuyg'ular platforma orqali uzatiladi",
    present_text_3:
      "Do'stlaringiz qancha ko'p bo'lsa, shunchalik ko'p xaridlar qilinadi.  Xaridlar qancha ko'p bo'lsa, keshbek shuncha ko'p bo'ladi.  Ushbu oddiy usulda siz pul ishlab, super sovrin yutib olish imkoniyatiga ega bo'lishingiz mumkin.",
    present_text_4: `
Do'stingizning har bir buyurtmasi uchun, siz keshbek olasiz. Hatto sizning do'stingiz uning do'stini chaqirsa ham, siz ham keshbek olasiz. Birgalikda do'stlaringizni taklif qiling, kitob o‘qing hamda pul ishlab oling!`,
    present_text_5:
      "Hech qachon bunaqasi bo'lmagan,kitob o'qiganingiz uchun avtomobil!Bizda buning imkoni bor!Tezroq kitob o'qing va avtomobil sohibi bo'ling!",
  },
  {
    /// ru
    header_title: "BOOKS YOU HAVEN'T READ",
    button_text: "Find more books",
    recommmend: "Recommends",
    hot: "Bestsellers",
    collection: "Collection",
    newest: "Newest",
    company: "About Company",
    offerta: "Offer",
    help: "FAQ",
    contact: "Contacts",
    publishmishment: "Publishers",
    acompany: "Cooperation",
    author: "Author",
    library: "Purchased books",
    webmaster: "Webmaster",
    developers: "Developed by",
    search_title: "FIND YOUR FAVOURITE BOOK",
    search_placeHolder: "book title",
    search_button: "Search",
    genre: "Genres",
    datePublish: "Publish Date",
    search_type: "Type",
    sorting: "Sort",
    all: "All",
    user_title: "WIN YOUR CAR",
    mainReward: "Main Reward",
    level: "Level",
    hello: "Hi",
    countOfFriends: "Count of friends",
    countOfPurchase: "Count of purchase",
    sumPurchase: "Sum of purchase",
    reward: "Reward",
    referal_link: "Referal link",
    user_library: "Library",
    user_empty: "You haven`t books",
    copy: "Copy",
    copied: "Copied!",
    book_author: "Author",
    rating: "Rating",
    book_publish: "Publish date ",
    volume: "Volume",
    tags: "tags",
    pages: "pages",
    booksCount: "books",
    download_format: "Download format",
    download: "download",
    book_descript: "Description",
    purchasing: "Purchase",
    text: "Text",
    audio: "Audio",
    result: "Result",
    auth_title: "Sign In",
    enter_phone: "Enter phone number!",
    enter_code: "Enter confirmation code",
    getCode: "Get the code",
    submit: "Submit",
    notTaked: "Didn't receive!",
    register_title: "Create an Account!",
    enter_name: "Your Name..",
    save: "Save",
    welcome: "Welcome",
    link_title:
      "Share the link with your friends and family and get a chance to win SPARK!",
    more: "More",
    notNow: "Not Now",
    downloading: "Downloading",
    error: "Something went wrong!",
    goToCollection: "Show collection",
    win: "Win",
    carName: "car",
    agreement: "I accepted privacy and policy",

    self: "your",
    credentials: "Card Info",
    enterCard: "Enter card number!",
    cardButton: "Cashback to card",
    cashback: "Cashback",
    sale: "About Sale",
    present_register_1: "Sing up",
    present_register_2: "Purchase the book",
    present_register_3: "Invite",
    present_title_1: "and read books",
    present_title_2: "relax",
    present_title_3: "and earn money",
    present_title_4: "it's not just a return!",
    present_title_5: "Finally",
    present_text_1:
      "Our platform has more than 5000 books and magazines on various topics and hobbies. Here you will find the book of the soul, without leaving your home travel or plunge into a confusing crime detective.",
    present_text_2:
      "All books have the soul of a writer. All emotions are transmitted through the platform",
    present_text_3:
      "The more friends you call, the more purchases. The more purchases, the more cashbacks. In this simple way you can earn and achieve a chance to win a super prize.",
    present_text_4:
      "For the purchase of friends, you will receive cashback. Even if your a friend calls his friend, then you will receive cashback too. Invite friends, earn and read together.",
    present_text_5:
      "There has never been anything like that win a car for reading books. Read books faster and win a super prize. Not miss the chance!",
  },
];
// language[currentLang].add
export default language;
